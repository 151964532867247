import { MutableRefObject, useEffect } from 'react'
import {
  HeaderNavItemType,
  NavBarActiveKey,
} from 'app.layout/constant/pageLayout.type'

/**
 * @description 모바일 nav 리스트의 뒤쪽 탭이 활성화 될 경우 활성화 된 탭을 화면에 표시합니다
 * @param parentRef - 스크롤 효과를 적용할 부모 요소의 ref
 * @param navItems - 스크롤 효과를 적용할 요소들의 목록
 * @param targetTab - 현재 활성화된 탭
 *  */
interface UseNavTabScrollIntoViewProps {
  parentRef: MutableRefObject<HTMLAnchorElement[] | null[]>
  navItems: HeaderNavItemType[]
  targetTab: NavBarActiveKey
}

export const useNavTabScrollIntoView = ({
  parentRef,
  navItems,
  targetTab,
}: UseNavTabScrollIntoViewProps) => {
  useEffect(() => {
    navItems.forEach((menuItem, index) => {
      const isActiveTab = targetTab === menuItem.active

      if (isActiveTab) {
        parentRef.current[index]?.scrollIntoView({
          inline: 'start',
        })
      }
    })
  }, [targetTab])
}
