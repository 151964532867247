import { FC, useState } from 'react'
import { Badge, IconButton } from '@material-ui/core'
import Image from 'next/image'
import styled from 'styled-components'
import { useGqlChatMessageUnreadCountQuery } from 'generated/graphql'
import ChatAppModal from '@app.components/modals/ChatAppModal/ChatAppModal'
import getMaxCount from 'app.layout/desktop/Hearder/module/getMaxCount'

const HeaderChatIconMobile: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data, error } = useGqlChatMessageUnreadCountQuery()

  if (error) {
    return (
      <StyledWrapper>
        <div className="error-wrapper">
          <Image
            src="/static/images/chat/chat-icon.svg"
            alt="채팅"
            width={20}
            height={20}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper>
      <IconButton className="icon-button">
        <Badge
          badgeContent={getMaxCount(data?.chatMessageUnreadCount ?? 0) || null}
          color="secondary"
          overlap="rectangular"
        >
          <Image
            src="/static/images/chat/chat-icon.svg"
            alt="채팅"
            width={20}
            height={20}
            onClick={() => setIsModalOpen(true)}
          />
        </Badge>
      </IconButton>
      <ChatAppModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </StyledWrapper>
  )
}

export default HeaderChatIconMobile

const StyledWrapper = styled.div`
  position: relative;
  z-index: 5;
  transform: translateY(2px);

  .error-wrapper {
    padding: 8px;
  }

  .icon-button {
    padding: 8px;
  }
`
